<template>
  <main class="project-view" :class="{ pending }">
    <div class="horiz-container default-grid row-gap">
      <div class="project-view__content">
        <FancyBreadcrumbs class="project-view__breadcrumbs" :links="[
          { name: 'Проекты', to: { name: 'project-list' } },
          { name: project.title, to: { name: 'project-view', params: { id: project.id } } }
        ]" />
        <div class="project-view__header">
          <div class="project-view__heading">
            <h1 class="project-view__title">{{ project.title }}</h1>
            <div v-if="isOwner" class="project-view__controls">
              <NuxtLink :to="{ name: 'project-edit', params: { id: project.id } }" class="project-view__control"><IconEdit class="icon" /></NuxtLink>
              <PopperMenu
                offset-distance="10"
                placement="bottom"
              >
                <button class="project-view__control"><IconDotsVertical class="icon" /></button>
                <template #content>
                  <PopperMenuItem v-if="project.is_archive" @click="open">Открыть проект</PopperMenuItem>
                  <PopperMenuItem v-else @click="close">Закрыть проект</PopperMenuItem>
                  <PopperMenuItem @click="remove" danger>Удалить</PopperMenuItem>
                </template>
              </PopperMenu>
            </div>
          </div>

          <MobileOnly>
            <ProjectMeta :project="project" mobile />
          </MobileOnly>

          <MetaBadgeList separator class="project-view__badges">
            <MetaBadge v-if="project.period" primary><IconClock class="icon" />Срок: {{ project.period }} {{ declOfNum(project.period, ['день', 'дня', 'дней']) }}</MetaBadge>
            <ProjectViewsBadge :count="project.views_count" />
            <ProjectResponsesBadge :count="project.responses_count" />
            <ProjectCreatedAtBadge :datetime="project.created_at" />
          </MetaBadgeList>
          <Tabs v-if="isOwner" class="project-view__tabs" :tabs="[
            { name: 'Отклики', to: { name: 'project-responses', params: { id: project.id } }, isNew: project.new_responses_count > 0, note: project.new_responses_count > 0 ? `+${project.new_responses_count}` : null },
            { name: 'Описание', to: { name: 'project-view', params: { id: project.id } } }
          ]" bottom :fluid="isMobile" />
        </div>
        <div class="project-view__body">
          <NuxtPage :project="project" @read-responses="project.new_responses_count = 0" />
        </div>
      </div>
      <DesktopOnly>
        <div class="project-view__sidebar">
          <div class="project-view__meta">
            <ProjectMeta :project="project" />
          </div>
        </div>
      </DesktopOnly>
    </div>
  </main>
  <ClientOnly><PromptModal @provide="prompt = $event"/></ClientOnly>
</template>

<script setup>
import { IconClock, IconEdit, IconDotsVertical } from '@tabler/icons-vue'
import { closeProject, deleteProject, getProject, openProject, viewProject } from '~/api/projects'

const router = useRouter()
const route = useRoute()

const user = inject('user')

const { isMobile } = useBreakpoints()

const { data: project, pending, refresh } = await useAsyncData(() => getProject(route.params.id))
if (!project.value?.id) throw createNotFoundError()

useViewsTracker(`projects.${project.value.id}`, () => viewProject(project.value.id))

const isOwner = computed(() => project.value.owner_id === user.value?.id)

async function close () {
  await closeProject(project.value.id)
  await refresh()
}
async function open () {
  await openProject(project.value.id)
  await refresh()
}

const prompt = ref()
async function remove () {
  if (
    !await prompt.value({
      title: `Вы уверены, что хотите удалить проект?`,
      description: 'Проект и все отклики будут удалены безвозвратно',
      danger: true,
      accept: 'Удалить'
    })
  ) return

  await deleteProject(project.value.id)
  await router.replace({ name: 'project-list' })
}

useHead({
  title: computed(() => project.value.title)
})
useServerSeoMeta({
  description: computed(() => project.value.description)
})
</script>

<style scoped lang="scss">
.project-view {
  &__content {
    grid-column: span 9;
    @media (max-width: 1256px) {
      grid-column: span 12;
    }
  }
  &__breadcrumbs {
    margin-bottom: 16px !important;
  }
  &__header {
    margin-bottom: 32px;
    &:not(:has(.project-view__tabs)) {
      padding-bottom: 32px;
      border-bottom: 1px solid var(--color-separator);
      @media (max-width: 920px) {
        padding-bottom: 24px;
      }
    }
    @media (max-width: 920px) {
      margin-bottom: 24px;
    }
  }
  &__heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    @media (max-width: 920px) {
      gap: 16px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  &__controls {
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    @media (max-width: 920px) {
      margin-top: 3px;
    }
  }
  &__control {
    &, .icon {
      width: 24px;
      height: 24px;
    }
    display: block;
    color: var(--color-elements-secondary);
    transition: .2s color;
    cursor: pointer;

    &:hover {
      opacity: 1;
      color: var(--color-elements-primary);
    }
  }
  &__badges {
    margin-top: 18px;
    @media (max-width: 920px) {
      padding: 0 15px;
      margin-top: 16px;
      margin-left: -15px;
      width: calc(100% + 30px) !important;
      max-width: unset;
    }
  }
  &__tabs {
    margin-top: 8px;
    @media (max-width: 920px) {
      margin-top: 16px;
      margin-left: -15px;
      width: calc(100% + 30px) !important;
    }
  }
  &__body {
    color: var(--color-elements-primary);
  }

  &__sidebar {
    grid-column: span 3;
    @media (max-width: 1256px) {
      grid-column: span 12;
    }
    @media (max-width: 920px) {
      display: none;
    }
  }
  &__meta {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--color-elements-quantery);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    position: sticky;
    top: 120px;
  }
}
</style>
