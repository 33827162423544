
export default function useViewsTracker (key, handler, { delay = 10 * 60 * 1000 } = {}) {
  if (process.server) return

  const viewTimestamps = useLocalStorage('view-timestamps', () => ({}))
  const timestamp = viewTimestamps.value[key]
  viewTimestamps.value[key] = Date.now()
  if (timestamp && Date.now() - new Date(timestamp) <= delay) return

  handler?.()
}
